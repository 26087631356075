<template>
    <div class="container">
      <header class="jumbotron" >
        <div style="background-image: url('../../public/greengrass.avif')">
        <h3>
        <!-- <p style="background-image: url('../../public/images.jpg')" > -->
          Ahnlund Consulting AB erbjuder tjänster inom Product Lifecycle Management, Product Modelling och Product Configuration Management.
        </h3>
      </div>
      </header>
        <p>
          Jag heter Jonas Ahnlund och har i över 15 år arbetat inom både stora och små företag med förändringsarbete och införande av olika systemlösningar anpassade till företagens processer. </p>
        <p>    
          Står ni inför en större förändring där mer struktur inom design och tillverkning och ett uttökat försäljningserbjudande är målsättningen så har ni kommit helt rätt.   
        </p>
        <p>
          Jag har arbetat med processförändringar inom globala organisationer från förstudie till genomförande med kravställning mot IT, test, utbildning och implementation av nya processer samt first-line support i samband med go-live.
          Med en pragmatisk inställning till att systemen ska supportera arbetsprocesserna och att tekniska lösningar skapar nya möjligheter till ökad lönsamhet och kanske helt nya affärsmodeller tar jag ett helhetsperspektiv över verksamheten.
        </p>
        <p>
          Går ni i tankar om hur ni kan dra nytta av att strukturera ert utvecklingsarbete i ett PLM system, eller skapa en bättre produktstruktur genom modularisering av produkt arkitekturen, kanske en ny kundkonfigurator eller allt av det? 
          Kanske att ni vill skapa er egna digital-twin genom design automation för bättre simuleringar eller för att skapa augmented reality instruktioner till er service personal så tveka inte att kontakta mig.
        </p>
        <table>
          <tr>
            <td>Email: <a href="mailto:jonas@ahnlundconsulting.se">Jonas Ahnlund</a></td>
          </tr>
          <tr>  
            <td>Telefon: +46 732 43 00 07</td>
          </tr>

        </table>
    </div>
  </template>
  
  <script>
//  import UserService from "../services/user.service";
  
  export default {
    name: "HomeView",
    data() {
      return {
      };
    },
//     mounted() {
//       UserService.getPublicContent().then(
//         (response) => {
//           this.content = response.data;
// //          this.content = "här kan jag skriva vad jag vill, och det tänker jag göra"
//         },
//         (error) => {
//           this.content =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();
//         }
//       );
//     },
  };
  </script>