import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:40321/api';
//const API_URL = 'http://2.56.29.252:8080/api';
//const API_URL = 'https://ahnlundconsulting.se:8090/api';



class TimeService {
  getTimes(data) {
		return axios.post(API_URL+ "/times", {data}, { headers: authHeader() })  
  }

	createTime(data) {
		return axios.post(API_URL + "/time", {data}, {headers: authHeader() })
	}

	updateTime(map) {
		const data = map.payload;
		console.log("TimeService.updateTime", data);
		return axios.put(API_URL + `/time/${map.id}`, {data}, {headers: authHeader() })
	}

	deleteTime(id) {
		console.log("TimeService.deleteTime", id);
		return axios.delete(API_URL + `/time/${id}`, {headers: authHeader() })
	}
	// för invoice
	getInvoicedTimes(data) {
		console.log("Timeservice.getInvoicedTimes", data);
		return axios.post(API_URL+ "/times/invoice", {data}, { headers: authHeader() })  
	}
	createCompany(data) {
		return axios.post(API_URL + "/company", {data}, {headers: authHeader() })
	}
	deleteCompany(id) {
		return axios.delete(API_URL + `/company/${id}`, {headers: authHeader() })
	}
	updateCompany(map) {
		const data = map.payload;
		return axios.put(API_URL + `/company/${map.id}`, {data}, {headers: authHeader() })
	}
}


export default new TimeService();