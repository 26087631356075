import TimeService from '../services/time.service';
import UserService from '../services/user.service';
//import modal from './modals';

export const time = {
//	namespaced: true,
	state: {
		users: [],
		times: [],
		invoicedTimes: [],
		companies: [],
		selectedTimeId: 0,
		current_month: undefined | 8,
		current_year: undefined | 2023,
		total_amount: 0,
		total_time: 0,
		selected_user: 1,
		selected_company_id: 0,
		selected_company: [],
		total_invoiced_time: 0,
		total_invoiced_amount: 0,
	},
	modules: {
//		modal,
	},
	getters: {
		getUsers: (state) => state.users,
		getTimes: (state) => state.times,
		getInvoicedTimes: (state) => state.invoicedTimes,
		getCompanies: (state) => state.companies,
		getCurrentMonth: (state) => state.current_month,
		getCurrentYear: (state) => state.current_year,
		getSelectedTimeId: (state) => state.selectedTimeId,
		getTotalTime: (state) => state.total_time,
		getTotalAmount: (state) => state.total_amount,
		getSelectedUser: (state) => state.selected_user,
		getSelectedCompany: (state) => state.selected_company,
		getSelectedCompanyId: (state) => state.selected_company_id,
		getTotalInvoicedTime: (state) => state.total_invoiced_time,
		getTotalInvoicedAmount: (state) => state.total_invoiced_amount,
	},
	actions: {
		async getUsers({commit}) {
			try {
				const data = await UserService.getAllUsers();
				console.log("$store.getUsers", data);
				commit("SET_USERS", data);
			}
			catch (error) {
				console.log(error);
			}
		},
		// Administrera företag
		async getCompanies({commit}) {
			try {
				const data = await UserService.getAllCompanies();
				console.log("$store.getCompanies", data);
				commit("SET_COMPANIES", data);
//				commit("SET_SELECTED_COMPANY", 1); // ta alltid första i arrayen
			}
			catch (error) {
				console.log(error);
			}

		},
		// hämta alla tider för en månad sparad is store
		async fetchTimes({ commit }) {
			let date = this.getters.getCurrentYear + "-" + this.getters.getCurrentMonth;
			console.log("$store.fetchTimes=", date);
			const payload = {
				username: this.getters.getSelectedUser,
				month: date
			}
			console.log("fetchTimes payload", payload);
			try {
				const data = await TimeService.getTimes(payload);
				console.log("fetchTimes data=", data);
				commit("SET_TIMES", data);
				commit("SET_TOTAL_TIME", data);
				commit("SET_TOTAL_AMOUNT", data);
			}
			catch (error) {
				alert(error);
				console.log(error);
			}
		},
		// skapa en ny tid oavsett när och behöver inte vara den inloggade användaren
		async createTime( {commit}, payload){
			console.log("store update createTime", payload);
			try {
				const data = await TimeService.createTime(payload);
				console.log("createtime Response", data);
			}
			catch(error) {
				alert(error);
				console.log(error);
			}
			commit("SET_SELECTED_TIME_ID", 0);
			this.dispatch("fetchTimes");	// vill ju inte duplicera koden här
		},
		// updatera en tid som redan finns
		async updateTime( {commit}, map) {
			try{
				await TimeService.updateTime(map);
			}
			catch(error){
				console.log("Store.updateTime", error);
			}
			commit("SET_SELECTED_TIME_ID", 0);
			this.dispatch("fetchTimes");
		},

		async deleteTime({commit}, id){
			try {
				await TimeService.deleteTime(id);
			}
			catch(error){
				console.log("Store.deleteTime", error);
			}
			commit("SET_SELECTED_TIME_ID", 0);
			this.dispatch("fetchTimes");
		},

		setSelectedCompany({commit}, id){
			commit("SET_SELECTED_COMPANY", id);
		},

		async createCompany( {commit}, payload){
			console.log("store.createCompany", payload);
			try {
				const data = await TimeService.createCompany(payload);
				console.log("response createCompany", data);
			}
			catch(error) {
				alert(error);
				console.log(error);
			}
			commit("SET_SELECTED_COMPANY_ID", 0)
			this.dispatch("getCompanies");
		},
		async updateCompany( {commit}, map) {
			try{
				await TimeService.updateCompany(map);
			}
			catch(error){
				console.log("Store.updateCompany", error);
			}
			commit("SET_SELECTED_COMPANY_ID", 0);
			this.dispatch("getCompanies");
		},
		async deleteCompany( {commit}, id) {
			try{
				await TimeService.deleteCompany(id);
			}
			catch(error){
				console.log("Store.deleteCompany", error);
			}
			commit("SET_SELECTED_COMPANY_ID", 0);
			this.dispatch("getCompanies");
		},

		// INVOICED AVDELNINGEN
		async fetchInvoicedTimes({ commit }) {
			let date = this.getters.getCurrentYear + "-" + this.getters.getCurrentMonth;
			console.log("$store.fetchInvoicedTimes=", date);
			const payload = {
				company: this.getters.getSelectedCompanyId,  // måste se till att rätt företag används här
				month: date
			}
			console.log("fetchInvoicedTimes payload", payload);
			try {
				const data = await TimeService.getInvoicedTimes(payload);
				console.log("fetchInvoicedTimes data=", data.data);
				commit("SET_INVOICED_TIMES", data);
				commit("SET_TOTAL_INVOICED_TIME", data);
				commit("SET_TOTAL_INVOICED_AMOUNT", data);
			}
			catch (error) {
				alert(error);
				console.log(error);
			}
		},

	}, // actions

	mutations: {
		SET_USERS(state, users) {
			console.log("SET_USERS", users.data)
			state.users = users.data;
		},
		SET_COMPANIES(state, companies) {
			console.log("SET_COMPANIES", companies.data)
			state.companies = companies.data;
			// make sure there always one company selected
			state.selected_company = state.companies[0];
		},
		// mutations for time functionality 
		SET_TIMES(state, times) {
			state.times = times.data;
			console.log("SET_TIMES", state.times);
		},

		SET_SELECTED_TIME_ID(state, id) {
			state.selectedTimeId = id;
		},

		SET_CURRENT_MONTH(state, current_month) {
			state.current_month = ("0" + current_month).substr(-2);
			console.log("mutation setMonth=", state.current_month);
		},

		SET_CURRENT_YEAR(state, current_year) {
			state.current_year = current_year;
		},
		SET_SELECTED_USER(state, selected_user) {
			state.selected_user = selected_user;
		},

		SET_SELECTED_COMPANY_ID(state, id){
			state.selected_company_id = id;	
			if (id === 0){
				state.selected_company = [{
					id: 0,
					name: "",
					address: "",
					zip_code: "",
					city: "",
					contact: "",
					VATnr: "",
					Kostnadsst: ""
				}];
			}
		},

		SET_SELECTED_COMPANY(state, id) {
			state.selected_company_id = id;
			if (id != 0){
				for (var i = 0; i < state.companies.length; i++){
					if (state.companies[i].id == id){
						state.selected_company = state.companies[i];
					}
				}
			}
			else {
				state.selected_company = [{
					id: 0,
					name: "",
					address: "",
					zip_code: "",
					city: "",
					contact: "",
					VATnr: "",
					Kostnadsst: ""
				}];
			}
		},

		SET_TOTAL_TIME(state, times) {
			let sum = 0;
			times.data.forEach((h) => {
				sum += Number(h.total_time);
			});
			state.total_time = sum;
		},
		SET_TOTAL_AMOUNT(state, times) {
			let sum = 0;
			times.data.forEach((h) => {
				if (h.invoice) {
					sum += Number(h.amount);
				}
			});
			state.total_amount = sum;
		},
		// mutation for the invoiced times rows
		SET_INVOICED_TIMES(state, times) {
			state.invoicedTimes = times.data;
			console.log("SET_INVOICED_TIMES", state.invoicedTimes);
		},
		SET_TOTAL_INVOICED_TIME(state, times) {
			console.log("state.SET_TOTAL_INVOICED_TIMES", times.data);
			let sum = 0;
			times.data.forEach((h) => {
				sum += Number(h.total_time);
			});
			state.total_invoiced_time = sum;
		},
		SET_TOTAL_INVOICED_AMOUNT(state, times) {
			let sum = 0;
			times.data.forEach((h) => {
				if (h.invoice) {
					sum += Number(h.amount);
				}
			});
			state.total_invoiced_amount = sum;
		},

	},

}
