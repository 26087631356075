import { createStore } from "vuex";
import { auth } from "./auth.module";
import { modal } from "./modals";
import { time } from "./time.module";

const store = createStore({
  modules: {
    auth,
    modal,
    time
  },
});

export default store;