/* eslint-disable */
<template>
	<div class="container">
		<div class="row">
			<div class="col-md-7 mrgnbtm">
				<h2 id="HeadLabel"></h2>
				<form>
					<div class="row">
						<div class="input">
							<label htmlFor="name">Företagsnamn</label>
							<input type="text" class="form-control" v-model="company.name" id="Name" /> 
                        </div>
                    </div>
					<div class="row">
						<div class="input">
							<label htmlFor="address">Adress</label>
							<input type="text" class="form-control" v-model="company.address" id="Address" />
						</div>
						<div class="input">
							<label htmlFor="zip_code">Postnr</label>
							<input type="text" class="form-control"  v-model="company.zip_code" id="Zip_code" />
						</div>
						<div class="input">
							<label htmlFor="city">Stad</label>
							<input type="text" class="form-control" v-model="company.city" id="City" />
						</div>
					</div>
					<div class="row">
						<div class="input">
							<label for="contact">Kontakt</label>
							<input type="text" class="form-control" v-model="company.contact" id="Contact" />
						</div>
					</div>
					<div class="row">
						<div class="input">
							<label for="VATnr">VATnr</label>
							<input type="text" class="form-control" v-model="company.VATnr" id="VATnr" />
						</div>
					</div>
					<div class="row">
						<div class="input">
							<label for="Kostnadsst">Märke/kostnst/ordernr</label>
							<input type="text" class="form-control" v-model="company.Kostnadsst" id="Kostnadsst" />
						</div>
					</div>

					<button type="button" @click='createCompany()' class="btn btn-danger" id="SaveButton"></button>
				</form>
			</div>
		</div>
	</div>
</template>
  

<script>
//import modal from "./TimeModal.vue";
  export default {
    name: 'CreateCompany',
    data() {
      return {
        
      }
    },
    methods: {
		mounted() {
			document.getElementById("SaveButton").value = "Save company";
		},

		/* Skapa en ny eller editera ett nytt företag*/
       createCompany() {
			console.log("skapa company", this.company);
			let myId = this.company.id;  // lite onödigt kanske när det finns i company
			const payload = this.company;
			console.log("CreateCompany", myId);
			if (myId === 0 || myId === undefined){
				this.$store.dispatch('createCompany', payload);  // api anropet som skapar ny tid
			}	
			else {
				const map = {
					id: myId,				
					payload: this.company,
				}
				console.log("update", map);
				this.$store.dispatch('updateCompany', map);
			}											
			this.$store.commit("SET_SELECTED_COMPANY", 0)												
			this.$store.dispatch("modal/close","company-modal");  // stänger popup dialogen för både edit och new company
		},
    },
    computed:{
        company() {
            if (this.$store.state.time.selectedCompanyId != 0){
                return this.$store.getters.getSelectedCompany[0];
            }
            else {
                return {
					id: 0,
                    name: "",
                    address: "",
                    zip_code: "",
                    city: "",
                    contact: "",
                    VATnr: "",
                    Kostnadsst: ""
                }
            }
                // console.log("mounted", company.address);
                // document.getElementById("Name").value = company.name;
                // document.getElementById("Address").value = company.address;
                // document.getElementById("Zip_code").value = company.zip_code;
                // document.getElementById("City").value = company.city;
                // document.getElementById("Contact").value = company.contact;
                // document.getElementById("VATnr").value = company.VATnr;
                // document.getElementById("Kostnadsst").value = company.Kostnadsst;
                // document.getElementById("SaveButton").value = "Save company";
        },
	},
  }
  </script>