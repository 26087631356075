import { createWebHistory, createRouter } from "vue-router";
import HomeView from "./components/Home.vue";
import LoginView from "./components/Login.vue";
import RegisterView from "./components/Register.vue";
// import BoardAdmin from "./components/TimeComponent.vue";
// lazy-loaded
const ProfileView = () => import("./components/Profile.vue")
//const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardAdmin = () => import("./components/TimeComponent.vue")
const BoardInvoice = () => import("./components/InvoiceComponent.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const BoardCompany = () => import("./components/companyComponent.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    component: HomeView,
  },
  {
    path: "/login",
    component: LoginView,
  },
  {
    path: "/register",
    component: RegisterView,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: ProfileView,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/admin/invoice",
    name: "/admin/invoice",
    component: BoardInvoice,
  },
 {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/admin/companies",
    name: "/admin/companies",
    component: BoardCompany,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/home', '/register', '/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/home');
  } else {
    next();
  }
});

export default router;