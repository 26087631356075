<template>
	<modal name="company-modal">
		<p>Add or edit company</p>
		<CreateCompanyComponent/>
	</modal>
</template>

<script>
	import Modal from "./Modal.vue"
	import CreateCompanyComponent from "./CreateCompanyComponent.vue"
	

	export default {
		name: "CompanyModal",
		components: { 
			Modal, 
			CreateCompanyComponent 
		},
	}
</script>

	
