import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:40321/api';
//const API_URL = 'http://2.56.29.252:8080/api';
//const API_URL = 'https://ahnlundconsulting.se:8090/api';


class UserService {
  getPublicContent() {
    return axios.get(API_URL + '/all');
  }

  getUserBoard() {
    return axios.get(API_URL + '/user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + '/mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + '/admin', { headers: authHeader() });
  }
  getAllUsers() {
    return axios.get(API_URL + "/users", { headers: authHeader() });  
  }
  getAllCompanies() {
    return axios.get(API_URL + "/companies", { headers: authHeader() });
  }
}

export default new UserService();