/* eslint-disable */
<template>
	<div class="container">
		<div class="row">
			<div class="col-md-7 mrgnbtm">
				<h2 id="HeadLabel"></h2>
				<form>
					<div class="row">
						<label htmlFor="User">User&nbsp;&nbsp;</label>
						<select class="select" id="User" name="user" >
							<option v-for="user in getUsers" :key="user.id" :value="user.id">
								{{ user.username }}
							</option>	
						</select>
					</div>
					<div class="row">
						<div class="date">
							<label htmlFor="start-time">Start time</label>
							<input type="datetime-local" class="form-control" name="Start time" id="start_time" placeholder="Start Time" />
						</div>
						<div class="date">
							<label htmlFor="end-time">End time</label>
							<input type="datetime-local" class="form-control"  name="End time" id="end_time" placeholder="End time" />
						</div>
						<div class="input">
							<label htmlFor="deduction">Paused time</label>
							<input type="number" class="form-control" v-model="deduction" id="deduction" />
						</div>
					</div>
					<div class="row">
						<div class="textInput">
							<label for="note">Time specification</label>
							<textarea type="text" class="form-control" v-model="note" name="Note" id="note" placeholder="Time specification" />
						</div>
					</div>
					<div class="row">
						<label for="customer">Customer</label><br/>
						<select class="form-control" id="customer" name="customer" >
							<option v-for="company in getCompanies" :key="company.id" :value="company.id">
								{{ company.name }}
							</option>	
						</select>
					</div>
					<div class="row">
						<label for="price">Price</label>
						<input type="number" id="price" name="price" v-model="price" />
						<label for="invoice">Invoice&nbsp;&nbsp;</label>
						<input type="checkbox" id="invoice" name="invoice" v-model="invoice"/>
					</div>

					<button type="button" @click='createTime()' class="btn btn-danger" id="SaveButton"></button>
				</form>
			</div>
		</div>
	</div>
</template>
  

<script>
//import modal from "./TimeModal.vue";
  export default {
    name: 'CreateTime',
    data() {
      return {
        start_time: '',
        end_time: '',
        note: '',
      }
    },
	mounted() {
		if (this.$store.state.time.selectedTimeId != 0) {
			let id = this.$store.state.time.selectedTimeId;
			document.getElementById("HeadLabel").innerHTML = "Edit time record";
			document.getElementById("SaveButton").innerHTML = "Update time record";
			let time = this.getTime(id);
			document.getElementById("User").value = time.owner_id;
			let st = time.start_time.replace('.000Z', '');
			let et = time.end_time.replace('.000Z', '');
			document.getElementById("start_time").defaultValue = st;
			document.getElementById("end_time").defaultValue = et;
			this.deduction = time.deduction;
			this.total_time = time.total_time;
			this.note = time.note;
			this.price = time.price;
			this.amount = time.amount;
			this.customer = time.customer;
			this.invoice = time.invoice === 1? true: false;
		}
		else{
			// Även om tiden är ny så kan vi ju sätta några defaultvärden
			document.getElementById("HeadLabel").innerHTML = "Edit time record";
			document.getElementById("SaveButton").innerHTML = "Update time record";
	//		document.getElementById("deduction").value = 0.5;
	//		document.getElementById("price").value = 1045;
			document.getElementById("invoice").checked = true;
			this.deduction = 0.5;
			this.price = 1045;
		}
	},


    methods: {
		/* Hämta en tidrad med id */
		getTime(id) {
			let times = this.$store.getters.getTimes;
			console.log("getTime", times);
			let tim = times.find( time => time.id === id);
			console.log("Tim", tim);
			return tim;
		},
		/* Skapa en ny eller editera en tid*/
       createTime() {
			if (document.getElementById("start_time").value == ''){
				alert("Please select a start time");
				return;
			}
			if (document.getElementById("end_time").value == ''){
				alert("Please select a end time");
				return;
			}
            this.total_time = (new Date(document.getElementById("end_time").value).getTime()- new Date(document.getElementById("start_time").value).getTime())/(1000*60*60) - this.deduction;
            this.amount = this.total_time * this.price;
			const payload = {
                owner_id: document.getElementById("User").value,
				start_time: document.getElementById("start_time").value,
				end_time: document.getElementById("end_time").value,
                deduction: this.deduction,
                total_time: this.total_time,
                note: this.note,
                price: this.price,
                amount: this.amount,
                customer: document.getElementById("customer").value,
                invoice: document.getElementById("invoice").checked === true? 1: 0,
            }
//            console.log("skapa tid", payload);
			let myId = this.$store.state.time.selectedTimeId;
			console.log("myId", myId);
			if (this.$store.state.time.selectedTimeId === 0){
				this.$store.dispatch('createTime', payload);  // api anropet som skapar ny tid
			}	
			else {
				const map = {
					id: myId,				// mappa in time.id i anropet så det kommer som en queryparam
					payload: payload,
				}
				console.log("update", payload);
				this.$store.dispatch('updateTime', map);
			}											// behöver ett som bara uppdaterar tid id
			this.$store.commit("SET_SELECTED_TIME_ID", 0)												
			this.$store.dispatch("modal/close","time-modal");  // stänger popup dialogen för både edit och new time
		},
    },

    computed:{
		getUsers() {
			return this.$store.getters.getUsers;
		},
		getCompanies() {
			return this.$store.getters.getCompanies;
		}
	},
  }
  </script>