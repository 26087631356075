<template>
	<modal name="time-modal">
		<p>Add or edit time</p>
		<CreateTimeComponent/>
	</modal>
</template>

<script>
	import Modal from "./Modal.vue"
	import CreateTimeComponent from "./CreateTimeComponent.vue"
	

	export default {
		name: "TimeModal",
		components: { 
			Modal, 
			CreateTimeComponent 
		},
	}
</script>

	
